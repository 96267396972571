import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import Box from '@mui/material/Box';
import Layout from "../components/layout";
import Seo from "../components/seo";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react'
import DatePicker from '../components/DatePicker'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NumberChip as Chip } from '../components/Chip';
import ProductList from '../components/ProductList'
const { useEffect } = React;


const itemData = [
    {
      img: 'https://cdn.meiziya.com/MZY/653f142d564ccb08ee8ff66e',
      title: 'Wood 木 - 肝胆女性入门调理',
      author: 'Liver & Gallbladder Basic ',
      rows: 2,
      cols: 2,
      featured: true,
    },
    {
      img: 'https://cdn.meiziya.com/MZY/653f16da564ccb08ee8ff670',
      title: 'Wood 木 - 肝胆男性入门调理',
      author: 'Liver & Gallbladder Basic',
    },
    {
      img: 'https://cdn.meiziya.com/MZY/653f17ad564ccb08ee8ff672',
      title: 'Fire 火 - 心小肠女性入门调理',
      author: 'Heart & Small Intestine Basic Rejuvenation',
    },
    {
      img: 'https://cdn.meiziya.com/MZY/653f1950564ccb08ee8ff674',
      title: 'Fire 火 - 心小肠女性入门调理',
      author: 'Heart & Small Intestine Basic Rejuvenation',
      cols: 2,
    },
    {
      img: 'https://cdn.meiziya.com/MZY/653f1abd564ccb08ee8ff676',
      title: 'Earth 土 - 脾胃女性入门调理',
      author: 'Spleen & Stomach Basic Rejuvenation For Ladies',
      cols: 2,
    },
    {
      img: 'https://cdn.meiziya.com/MZY/653f1b8a564ccb08ee8ff678',
      title: 'Earth 土 - 脾胃女性入门调理',
      author: 'Spleen & Stomach Basic Rejuvenation For Ladies',
      rows: 2,
      cols: 2,
      featured: true,
    },
    {
      img: 'https://cdn.meiziya.com/MZY/653f1c65564ccb08ee8ff67a',
      title: 'Metal 金 -肺大肠男性入门调理',
      author: 'Lung & Large Intestine Basic Rejuvenation',
    },
    {
      img: 'https://cdn.meiziya.com/MZY/653f1d4a564ccb08ee8ff67c',
      title: 'Metal 金 -肺大肠男性入门调理',
      author: 'Lung & Large Intestine Basic Rejuvenation',
    },
    {
      img: 'https://cdn.meiziya.com/MZY/653f1e25564ccb08ee8ff67e',
      title: 'Water 水 - 肾膀胱女性入门调理',
      author: 'Kidney & Bladder Basic Rejuvenation For Ladies',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://cdn.meiziya.com/MZY/653f1ec4564ccb08ee8ff680',
      title: 'Water 水 - 肾膀胱女性入门调理',
      author: 'Kidney & Bladder Basic Rejuvenation For Ladies',
    },

  ];

const IndexPage = () => {
  const [value, setValue] = React.useState(
    dayjs('1988-09-14T12:00:01'),
  );

  const [day, setDate] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [elements, setElements] = useState(null)
  const [accordion, setAccordion] = useState([])
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const handleChange = (newValue) => {
    console.log(newValue)
    setValue(newValue);
  };



  useEffect(() => {
    if (elements) {
      const accordionData = [
        {
          title: '健康五行分配',
          content: <>
            {
              Object.keys(elements).length && Object.keys(elements).map(k => <Box display="flex">

                <span styles={{ textTransform: 'capitalize' }}>{k}</span>: {
                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>

                    {
                      [...Array(elements[k] || 0).keys()].map(e => <Chip size="16px" num={k}>
                      </Chip>)
                    }

                  </Stack>
                }


              </Box>)

            }
          </>
        },
        {
          title: '分析',
          content: <>test</>
        },
        {
          title: '建议',
          content: <>test</>
        },
        {
          title: '',
          content: <Typography>
            <a href="https://api.whatsapp.com/send?phone=6587413159&amp;text=免费深入分析" style={{ color: "rgb(37, 211, 102)" }}>Whatsapp</a> 我们获取免费深入分析
          </Typography>
        }
      ]

      setAccordion(accordionData)
    }
  }, [elements])


  return (
    <Layout>
      <Seo title="Home" />
      <Box display="flex" justifyContent="center">

      
      <Stack display="flex" justifyContent="center" flexDirection="col">
      <h1>Products </h1>
        <ProductList itemData={itemData} />

      </Stack>
      </Box>


    </Layout>
  )
}

export default IndexPage
